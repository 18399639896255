import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import Content from 'components/Content';
import ServicePageTemplate from './ServicePageTemplate';

const ServicePage = ({ data }) => {
  const { markdownRemark: pageData } = data;
  const { frontmatter, html } = pageData;
  const { title, description, image, sections, quotes } = frontmatter;

  return (
    <Layout>
      <ServicePageTemplate
        contentComponent={Content}
        title={title}
        description={description}
        image={image}
        content={html}
        sections={sections}
        quotes={quotes}
      />
    </Layout>
  );
};

ServicePage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default ServicePage;

export const servicePageQuery = graphql`
  query ServicePage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        description
        image {
          childImageSharp {
            gatsbyImageData(quality: 90, layout: FULL_WIDTH)
          }
        }
        quotes
        sections {
          id
          title
          text
          image {
            alt
            image {
              childImageSharp {
                gatsbyImageData(quality: 90, layout: FULL_WIDTH)
              }
            }
          }
        }
      }
    }
  }
`;
